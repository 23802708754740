import {
  InfoCircleOutlined,
  PlusCircleOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { Button, Col, Menu, Popover, Row, Select } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import logo from '../assets/logo.png';
import styled from 'styled-components';
import { useWallet } from '../utils/wallet';
import { ENDPOINTS, useConnectionConfig } from '../utils/connection';
import Settings from './Settings';
import CustomClusterEndpointDialog from './CustomClusterEndpointDialog';
import { EndpointInfo } from '../utils/types';
import { notify } from '../utils/notifications';
import { Connection } from '@solana/web3.js';
import WalletConnect from './WalletConnect';
import AppSearch from './AppSearch';
import {getSwapPageUrl, getTradePageUrl} from '../utils/markets';

const Wrapper = styled.div`
  background-color: #0d1017;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0px 30px;
  flex-wrap: wrap;
`;
const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  color: #2abdd2;
  font-weight: bold;
  cursor: pointer;
  img {
    height: 30px;
    margin-right: 8px;
  }
`;

const EXTERNAL_LINKS = {
  '/learn': 'https://docs.projectserum.com/trade-on-serum-dex/trade-on-serum-dex-1',
  '/add-market': 'https://serum-academy.com/en/add-market/',
  '/wallet-support': 'https://serum-academy.com/en/wallet-support',
  '/dex-list': 'https://serum-academy.com/en/dex-list/',
  '/developer-resources': 'https://serum-academy.com/en/developer-resources/',
  '/explorer': 'https://solscan.io',
  '/srm-faq': 'https://projectserum.com/srm-faq',
  '/swap': 'https://swap.projectserum.com',
};

export default function TopBar() {
  const { connected, wallet } = useWallet();
  const {
    endpoint,
    endpointInfo,
    setEndpoint,
    availableEndpoints,
    setCustomEndpoints,
  } = useConnectionConfig();
  const [addEndpointVisible, setAddEndpointVisible] = useState(false);
  const [testingConnection, setTestingConnection] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const [searchFocussed, setSearchFocussed] = useState(false);

  const handleClick = useCallback(
      (e) => {
        if (!(e.key in EXTERNAL_LINKS)) {
          history.push(e.key);
        }
      },
      [history],
  );

  const onAddCustomEndpoint = (info: EndpointInfo) => {
    const existingEndpoint = availableEndpoints.some(
        (e) => e.endpoint === info.endpoint,
    );
    if (existingEndpoint) {
      notify({
        message: `An endpoint with the given url already exists`,
        type: 'error',
      });
      return;
    }

    const handleError = (e) => {
      console.log(`Connection to ${info.endpoint} failed: ${e}`);
      notify({
        message: `Failed to connect to ${info.endpoint}`,
        type: 'error',
      });
    };

    try {
      const connection = new Connection(info.endpoint, 'recent');
      connection
          .getBlockTime(0)
          .then(() => {
            setTestingConnection(true);
            console.log(`testing connection to ${info.endpoint}`);
            const newCustomEndpoints = [
              ...availableEndpoints.filter((e) => e.custom),
              info,
            ];
            setEndpoint(info.endpoint);
            setCustomEndpoints(newCustomEndpoints);
          })
          .catch(handleError);
    } catch (e) {
      handleError(e);
    } finally {
      setTestingConnection(false);
    }
  };

  const endpointInfoCustom = endpointInfo && endpointInfo.custom;
  useEffect(() => {
    const handler = () => {
      if (endpointInfoCustom) {
        setEndpoint(ENDPOINTS[0].endpoint);
      }
    };
    window.addEventListener('beforeunload', handler);
    return () => window.removeEventListener('beforeunload', handler);
  }, [endpointInfoCustom, setEndpoint]);

  const tradePageUrl = location.pathname.startsWith('/market/')
      ? location.pathname
      : getTradePageUrl();

  const swapPageUrl = location.pathname.startsWith('/swap/')
      ? location.pathname
      : getSwapPageUrl();

  return (
      <>
        <CustomClusterEndpointDialog
            visible={addEndpointVisible}
            testingConnection={testingConnection}
            onAddCustomEndpoint={onAddCustomEndpoint}
            onClose={() => setAddEndpointVisible(false)}
        />
        <Wrapper>
          <LogoWrapper onClick={() => history.push(tradePageUrl)}>
            <img src={logo} alt="" />
            {'NEKI DEX'}
          </LogoWrapper>
          <Menu
              mode="horizontal"
              onClick={handleClick}
              selectedKeys={[location.pathname]}
              style={{
                borderBottom: 'none',
                backgroundColor: 'transparent',
                display: 'flex',
                alignItems: 'flex-end',
                flex: 1,
              }}
          >
            <Menu.Item key={tradePageUrl} style={{ margin: '0 0px 0 10px' }}>
              DEX
            </Menu.Item>
            {/*{connected && (!searchFocussed || location.pathname === '/balances') && (*/}
            {/*  <Menu.Item key="/balances" style={{ margin: '0 0px' }}>*/}
            {/*    BALANCES*/}
            {/*  </Menu.Item>*/}
            {/*)}*/}
            <Menu.Item key={swapPageUrl} style={{ margin: '0 0px' }}>
              SWAP
            </Menu.Item>
            {connected && (!searchFocussed || location.pathname === '/orders') && (
                <Menu.Item key="/orders" style={{ margin: '0 0px' }}>
                  ORDERS
                </Menu.Item>
            )}
            {/*{connected && (!searchFocussed || location.pathname === '/convert') && (*/}
            {/*    <Menu.Item key="/convert" style={{ margin: '0 0px' }}>*/}
            {/*      CONVERT*/}
            {/*    </Menu.Item>*/}
            {/*)}*/}
            {(!searchFocussed || location.pathname === '/list-new-market') && (
                <Menu.Item key="/list-new-market" style={{ margin: '0 0px' }}>
                  ADD MARKET
                </Menu.Item>
            )}
          </Menu>
          <div
              style={{
                display: 'flex',
                alignItems: 'center',
                paddingRight: 5,
              }}
          >
            <AppSearch
                onFocus={() => setSearchFocussed(true)}
                onBlur={() => setSearchFocussed(false)}
                focussed={searchFocussed}
                width={searchFocussed ? '350px' : '35px'}
            />
          </div>
          <div>
            <Row
                align="middle"
                style={{ paddingLeft: 5, paddingRight: 5 }}
                gutter={16}
            >
              <Col>
                <PlusCircleOutlined
                    style={{ color: '#2abdd2' }}
                    onClick={() => setAddEndpointVisible(true)}
                />
              </Col>
              <Col>
                <Popover
                    content={endpoint}
                    placement="bottomRight"
                    title="URL"
                    trigger="hover"
                >
                  <InfoCircleOutlined style={{ color: '#2abdd2' }} />
                </Popover>
              </Col>
              <Col>
                <Select
                    onSelect={setEndpoint}
                    value={endpoint}
                    style={{ marginRight: 8, width: '150px' }}
                >
                  {availableEndpoints.map(({ name, endpoint }) => (
                      <Select.Option value={endpoint} key={endpoint}>
                        {name}
                      </Select.Option>
                  ))}
                </Select>
              </Col>
            </Row>
          </div>
          {connected && (
              <div>
                <Popover
                    content={<Settings autoApprove={wallet?.autoApprove} />}
                    placement="bottomRight"
                    title="Settings"
                    trigger="click"
                >
                  <Button style={{ marginRight: 8 }}>
                    <SettingOutlined />
                    Settings
                  </Button>
                </Popover>
              </div>
          )}
          <div>
            <WalletConnect />
          </div>
        </Wrapper>
      </>
  );
}
